import {Component} from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {HomepageHeroComponent} from "./hero/homepage-hero.component";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-base-layout',
  standalone: true,
  imports: [
    RouterOutlet,
    HomepageHeroComponent,
    NgIf
  ],
  templateUrl: './base-layout.component.html',
  styleUrl: './base-layout.component.scss'
})
export class BaseLayoutComponent {

}
