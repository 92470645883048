import { ImageLoaderConfig } from "@angular/common";
import { environment } from "../../environments/environment";
import pb from '@bitpatty/imgproxy-url-builder'

export const ImgProxyLoader = (c: ImageLoaderConfig) => {
  let builder = pb()

  if (c.loaderParams && c.loaderParams['preset']) {
    builder.preset(c.loaderParams['preset'] as any[] | any)
  } else {
    let options = {
      width: c.width,
      fit: 'fill'
    }

    builder.resize(options)
    builder.stripMetadata()
    builder.formatQuality({
      jpeg: 75,
      png: 75,
      avif: 60,
      webp: 60
    })
  }

  return builder.build({
    path: 'user-data/' + c.src,
    plain: true,
    signature: {
      key: environment.img_proxy_key,
      salt: environment.img_proxy_salt,
      size: 32
    },
    baseUrl: environment.img_proxy_url
  });
}
